import React , { useEffect, useState } from "react";
import styles from './Carousel.module.css';

export default function Carousel2({id,imgArray}){
    let viewportWidth = document.body.clientWidth;
    let viewportHeight = document.body.clientHeight;

    let imgWidth;
    let marginWidth;

    if(viewportWidth <= 900 && viewportHeight > viewportWidth){
        imgWidth = 0.8*viewportWidth;
        marginWidth = 0.1*viewportWidth;
    } else {
        imgWidth = 0.4*viewportWidth;
        marginWidth = 0.3*viewportWidth;
    }
    

    const [selected,setSelected] = useState(0);
    const [stateImgArray,setStateImgArray] = useState(imgArray);
    const [arrayLength,setArrayLength] = useState(imgArray.length);
    const [carouselPos,setCarouselPos] = useState((marginWidth)-(imgWidth));
    const [orderedArray,setOrderedArray] = useState([]);
    const [animationActive,setAnimationActive] = useState(true);
    const [pauseAuto,setPauseAuto] = useState(false);

    useEffect(() => {
        viewportWidth = document.body.clientWidth;
        viewportHeight = document.body.clientHeight;
        setStateImgArray(imgArray);
        setArrayLength(imgArray.length);
        if(imgArray.length > 1){
            setOrderedArray([imgArray[imgArray.length-1],...imgArray,imgArray[0]]);
            setCarouselPos((marginWidth)-(imgWidth));
        } else {
            setOrderedArray(imgArray);
        }
    },[imgArray,document.body.clientWidth]);
    
    let i = 0;
    let j = 0;

    const changeSelected = (val) => {
        console.log('pause!!!');
        setPauseAuto(true);
        let previousVal;
        setSelected((prev) => {
            previousVal = prev;
            return val;
        })
        setCarouselPos((marginWidth)-(imgWidth)-val*(imgWidth))
    }

    const changeSelectedAuto = () => {
        let previousVal;
        let newVal;
        if(!pauseAuto){
            setSelected((prev) => {
                console.log(prev);
                previousVal = prev;
                if(previousVal >= arrayLength - 1){
                    newVal = 0
                } else {
                    previousVal++
                    newVal = previousVal;
                }
                setCarouselPos((marginWidth)-(imgWidth)-newVal*(imgWidth))
                console.log(newVal);
                return newVal
            });
        }
        
    }

    const [opacity,setOpacity] = useState();
    
        const sectionScrollOpacity = () => {
            const element = document.getElementById('carousel').getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            // console.log('Top:',element.top,"vh",viewportHeight);
            if(element.top < viewportHeight*0.8){
                setOpacity('1');
            } else {
                setOpacity('0');
            }
            
        }
    
        useEffect(() => {
            window.addEventListener('scroll', sectionScrollOpacity);
            return () => {
                window.removeEventListener('scroll', sectionScrollOpacity);
            }
        },[]);

        useEffect(() => {
            let interval;
            interval = setInterval(() => {
                changeSelectedAuto();
            },1000);
            return () => {
                clearInterval(interval);
            }
        },[]);

        useEffect(() => {
            let timeout;
            console.log('ffffffsss',pauseAuto);
            timeout = setTimeout(() => {
                setPauseAuto(false);
            },5000);
            return () => {
                clearTimeout(timeout);
            }
        },[pauseAuto]);

    return(
        <div id="carousel" className={styles.largeContainer} style={{opacity: opacity}}>
            <div id={id} className={styles.container}>
                <div id={`${id}_inner`} className={`${styles.containerInner} ${animationActive? styles.containerAnimation : ''}`} style={{left: `${carouselPos}px`}}>
                    {orderedArray.map((img) => {
                        let k = i;
                        i++;
                        return (
                            <div onClick={() => {changeSelected(img.id)}} className={`${styles.imageContainer} ${selected === img.id? styles.selected : ''}`}>
                                <div className={styles.images} style={{backgroundImage: `url(${img.img})`}}></div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.imgDescription}>
                <h6><strong>{imgArray[selected].name}</strong></h6>
                <p>{imgArray[selected].description}</p>
            </div>
            <div className={styles.circleSelectorContainer}>
                {imgArray.map((img) => {
                        let k = j;
                        j++;
                        return (
                            <div onClick={() => {changeSelected(k)}} className={`${styles.circleSelector} ${selected === img.id? styles.selected : ''}`}>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}