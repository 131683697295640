import React, { useEffect, useState } from "react";
import styles from './Header.module.css';
import { ReactComponent as Logo} from '../../imgs/Stratela_logo_white-1.svg';
import { ReactComponent as Burger } from '../../imgs/designStrat/two lines-27.svg'
import { ReactComponent as Cross } from '../../imgs/icons/cross-33.svg'
import { NavLink, useNavigate } from "react-router-dom";

export default function Header({navigateToElement}){

    const navigate = useNavigate();

    const [mobileVersion,setMobileVersion] = useState(true);
    const [navBarShow,setNavBarShow] = useState(false);
    const [headerColor,setHeaderColor] = useState(false);
    const [lightTheme,setLightTheme] = useState();
    const [headerPos,setHeaderPos] = useState(0);
    const [theme,setTheme] = useState();
    const [textTheme,setTextTheme] = useState(false);


    const toggleNavBar = () => {
        setNavBarShow((prev) => !prev);
    }
    
    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setMobileVersion(newViewportWidth < 900);
        setNavBarShow(newViewportWidth > 900);
        console.log(window.scrollY);
    }

    const onScrollFunction = () => {
        if(window.location.pathname === '/home' || window.location.pathname === '/aboutUs'){
            setHeaderColor(window.scrollY > 0);
            if(window.scrollY < window.innerHeight){
                if(window.scrollY === 0){
                    setTheme('headerColorClear');
                    setTextTheme('headerTextWhite');
                } else {
                    setTheme('headerColorDark');
                    setTextTheme('headerTextWhite');
                } 
            } else {
                setTheme('headerColorLightBlue');
                setTextTheme('headerTextBlack');
            }            
        } else if (window.location.pathname.includes('/portfolio') && !window.location.pathname.includes('/portfolio/')) {
            if(window.scrollY === 0){
                setTheme('headerColorClear');
                setTextTheme('headerTextBlack');
            } else {
                setTheme('headerColorOffWhite');
                setTextTheme('headerTextBlack');
            } 
        } else if (window.location.pathname.includes('/portfolio/')) {
            if(window.scrollY === 0){
                setTheme('headerColorClear');
                setTextTheme('headerTextWhite');
            } else {
                setTheme('headerColorOffWhite');
                setTextTheme('headerTextBlack');
            } 
        } else {
            setTheme('headerColorLightBlue');
            setTextTheme('headerTextBlack');
        }
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        window.addEventListener('scroll', onScrollFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
            window.removeEventListener('scroll', onScrollFunction);
        }
    },[]);

    useEffect(() => {
        onScrollFunction();
    },[window.location.pathname])
    
    const handleLogoClick = () => {
        // window.scrollTo(0,0);
        navigateToElement('/home','');
    }

    const handleNavClick = () => {
        window.scrollTo(0,0);
        if(mobileVersion){
            setNavBarShow(false);
        }
    }
    const navigateAndClose = (pagePath,sectionId) => {
        navigateToElement(pagePath,sectionId);
        if(mobileVersion){
            setNavBarShow(false);
        }
    }

    return (
        <header style={{top: headerPos}} className={`${styles[theme]} ${styles[textTheme]}`}>
           <div className={styles.imgContainer} onClick={handleLogoClick}>
                <Logo />
           </div>
           <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayGrid: styles.hide}`} onClick={toggleNavBar}>
                <Burger />
           </div>
           <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                <ul>
                    <li className={styles.closeButton}>
                        <a onClick={() => setNavBarShow(false)}><Cross /></a>
                    </li>
                    <li>
                        <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="aboutUs" onClick={() => navigateAndClose('/home','aboutUsSection')}>About Us</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="portfolio" onClick={handleNavClick}>Portfolio</NavLink>
                    </li>  */}
                    <li>
                        <NavLink to="contact" onClick={handleNavClick}>Contact Us</NavLink>
                    </li>
                </ul>
           </nav>
        </header>
    )
}