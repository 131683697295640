import React, { useState , useEffect } from "react";
import styles from './PortfolioTiles.module.css';
import { useNavigate } from "react-router-dom";

export default function PortfolioTiles({data,selectedMobileTile,setSelectedMobileTile}) {
    const {projectName,briefDescription,tileImgHeight,tileImgs,uniqueId} = data;
    const navigate = useNavigate();

    const [hover,setHover] = useState(false);
    const [img2Opacity,setImg2Opacity] = useState('0');
    const [switchImageOn,setSwitchImageOn] = useState(false);

    useEffect(() => {
        let interval;
        if(switchImageOn){
            interval = setInterval(() => {
                setImg2Opacity((prev) => prev === '0'? '1' : '0');
            },2000);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        }
    },[switchImageOn]);

    const hoverTrue = () => {
        setHover(true);
    }

    const hoverFalse = () => {
        setHover(false);
    }

    const styleObject = {
        backgroundImage: `url(${tileImgs[0]})`
    }

    let interval;

    const imgScrollOpacity = (parentElementId,setterFunction) => {
        const element = document.getElementById(parentElementId).getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        clearInterval(interval);
        // if(element.top <= viewportHeight*(-0.1)){
        //     setterFunction('0');
        //     setSwitchImageOn(false);
        // } else if (element.top >= viewportHeight*0.5) {
        //     setterFunction('0');
        //     setSwitchImageOn(false);
        // } else if (element.top >= viewportHeight*(-0.1) && element.top <= viewportHeight*(0.5)){
        //     if(!switchImageOn){
        //         setterFunction('1');
        //         setSwitchImageOn(true);
        //     }
        // }

        if(element.top <= (viewportHeight*(0.5))+31 && element.bottom >= (viewportHeight*(0.5))-31){
            setSwitchImageOn(true);
        } else {
            setSwitchImageOn(false);
        }
        
    }

    const onScrollFunction = () => {
        imgScrollOpacity(uniqueId,setImg2Opacity);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollFunction);       
        return () => {
            window.removeEventListener('scroll', onScrollFunction);
            clearInterval(interval);
        }
    },[]);

    

    const handleClick = () => {
        navigate(`/portfolio/${uniqueId}`);
    }

    return (
        <div className={styles.tileContainer} onMouseEnter={hoverTrue} onMouseLeave={hoverFalse} onClick={handleClick}>
            <div className={styles.tileImgContainer}  >
                <div className={styles.image} style={styleObject}></div>
                <div className={`${styles.image2} ${hover? styles.image2show : styles.image2hide}`} style={{backgroundImage: `url(${tileImgs[1]})`}}></div>
            </div>
            <div id={uniqueId} className={styles.tileImgContainerMobile}>
                <div className={styles.image} style={styleObject}></div>
                <div className={`${styles.image2}`} style={{backgroundImage: `url(${tileImgs[1]})`, opacity: img2Opacity}}></div>
            </div>
            {/* <div className={styles.maskContainer}>
            </div>
            <div className={styles.maskContainerMobile} style={{opacity: switchImageOn? '1' : '0'}}>
            </div> */}
            <div className={styles.tiletextContainerInner}>
                <h6><strong>{projectName}</strong></h6>
                <p>{briefDescription}</p>
            </div>
            <div className={`${switchImageOn? styles.tiletextContainerInnerMobileON : styles.tiletextContainerInnerMobile}`}>
                <h6><strong>{projectName}</strong></h6>
                <p>{briefDescription}</p>
            </div>
        </div>
    )
}
//style={{height: tileImgHeight}}