import React , { useEffect, useState } from "react";
import styles from './Carousel.module.css';
import BannerReveal from "./BannerReveal";

export default function CarouselReveal2({id,imgArray = [{id:'test', name: 'Test', description: 'Test', imgs: ['','']}] }){
    let viewportWidth = document.body.clientWidth;
    let viewportHeight = document.body.clientHeight;

    let imgWidth;
    let marginWidth;

    if(viewportWidth <= 900 && viewportHeight > viewportWidth){
        imgWidth = 0.8*viewportWidth;
        marginWidth = 0.1*viewportWidth;
    } else {
        imgWidth = (10/12)*viewportWidth;
        marginWidth = (1/12)*viewportWidth;
    }

    const [selected,setSelected] = useState(0);
    const [stateImgArray,setStateImgArray] = useState(imgArray);
    const [carouselPos,setCarouselPos] = useState((marginWidth)-(imgWidth));
    const [orderedArray,setOrderedArray] = useState([]);
    const [animationActive,setAnimationActive] = useState(true);

    useEffect(() => {
        viewportWidth = document.body.clientWidth;
        viewportHeight = document.body.clientHeight;
        setStateImgArray(imgArray);
        if(imgArray.length > 1){
            setOrderedArray(imgArray);
            setCarouselPos((marginWidth));
        } else {
            setOrderedArray(imgArray);
        }
    },[imgArray,document.body.clientWidth]);

    const changeSelected = (val) => {
        let previousVal;
        setSelected((prev) => {
            previousVal = prev;
            return val;
        })
        setCarouselPos((marginWidth)-val*(imgWidth))
    }

    let i = 0;
    let j = 0;

    return(
        <div className={styles.largeContainer}>
            <div id={id} className={styles.container}>
                <div id={`${id}_innerReveal`} className={`${styles.containerInner} ${animationActive? styles.containerAnimation : ''}`} style={{left: `${carouselPos}px`}}>
                    {orderedArray.map((img) => {
                        i++;
                        return (
                            <div className={styles.slideContainer}>
                                <div onClick={() => changeSelected(img.id)} className={`${styles.imageContainer} ${styles.reveal} ${selected === img.id? styles.selected : ''}`}>
                                    <BannerReveal id={img.id} selectedState={selected === img.id} img1={img.imgs[0]} img2={img.imgs[1]}/>
                                </div>
                                <div onClick={() => changeSelected(img.id)} className={`${styles.leftArrow} ${selected === img.id? styles.selected : ''}`}>
                                    <p>&#60;</p>
                                </div>
                                <div onClick={() => changeSelected(img.id)} className={`${styles.rightArrow} ${selected === img.id? styles.selected : ''}`}>
                                    <p>&#62;</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.imgDescription}>
                <h6><strong>{imgArray[selected].name}</strong></h6>
                <p>{imgArray[selected].description}</p>
            </div>
            <div className={styles.circleSelectorContainer}>
                {imgArray.map((img) => {
                        let k = j;
                        j++;
                        return (
                            <div className={`${styles.circleSelector} ${selected === img.id? styles.selected : ''}`} onClick={() => changeSelected(img.id)}>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}